import clsx from 'clsx'
import { ReactHTML } from 'react'
import { returnLineBreaks } from '~/lib/returnLineBreaks'

export type Props = {
  type?: keyof Pick<ReactHTML, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p'>
  className?: string
  styleAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'p-small' | 'p-mini' | 'subline-20' | 'slider'
  children: React.ReactNode
}

export const Headline = ({ type, styleAs = 'h1', className, children }: Props) => {
  const HeadlineType = type || 'h1'
  const basicStyle = 'text-sg-black font-extralight break-words'
  const styling = {
    h1: 'text-40-45-sg lg:text-80-90-sg',
    h2: 'text-30-35-sg lg:text-70-80-sg',
    h3: 'text-20-25-sg lg:text-60-70-sg',
    h4: 'text-15-20-sg lg:text-50-60-sg',
    p: 'text-15-22-sg',
    'p-small': 'text-12-20-sg',
    'p-mini': 'text-10-16-sg',
    'subline-20': 'text-15-20-sg lg:text-20-25-sg',
    slider: 'text-20-25-sg lg:text-30-35-sg',
  }

  return (
    <HeadlineType className={clsx(styling[styleAs], basicStyle, className)}>
      {typeof children === 'string' ? returnLineBreaks(children) : children}
    </HeadlineType>
  )
}
