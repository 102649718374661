import clsx from 'clsx'
import { Entry, Maybe, Scalars } from '@contentfulTypes'
import { isExternal } from '~/lib/externalLink'
import { Link } from '~/elements/Link/Link'
import { log } from '~/lib/log'

export type ButtonStyle =
  | 'Main'
  | 'Secondary'
  | 'Tertiary'
  | 'AlternateColor' //Background Color and Text Color should be passed to Button in className prop
  | 'BigText'
  | 'MediumText'
  | 'MediumLinkText'
  | 'SmallText'
  | 'Main-light'
  | 'Main-dark'
  | 'Secondary-light'
  | 'Secondary-dark'
  | 'TextImgBig-light'
  | 'TextImgBig-dark'

type Props = {
  __typename?: 'Cta'
  ctaStyle?: Maybe<Scalars['String']>
  newCtaStyle?: Maybe<Scalars['String']>
  ctaText?: Maybe<Scalars['String']>
  eMailLink?: Maybe<Scalars['String']>
  linkExistingPage?:
    | {
        __typename?: 'Page' | undefined
        slug?: string | null | undefined
        title?: string | null | undefined
      }
    | null
    | undefined
  linkExternalPage?: Maybe<Scalars['String']>
  linkInternalUrl?: Maybe<Scalars['String']>
  phoneLink?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  children?: React.ReactNode | JSX.Element
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
  className?: string
  disabled?: boolean
  isTextMediumBold?: boolean
  textLinkIsWhite?: boolean
  linkToEntry?: Maybe<{ sys: { __typename?: 'Sys'; id: string } }>
  noStyle?: boolean
}

export const ContentfulButton = ({
  ctaStyle = 'MediumText',
  newCtaStyle,
  ctaText,
  eMailLink,
  linkExistingPage,
  linkExternalPage,
  linkInternalUrl,
  phoneLink,
  title,
  children,
  onClick,
  className,
  disabled,
  type,
  isTextMediumBold,
  textLinkIsWhite,
  linkToEntry,
  noStyle,
}: Props) => {
  const MainBasic = `text-center inline-block text-[16px] leading-[22px] border border-[2px] border-solid px-[42px] py-[21px] lg:transition-color lg:duration-300 lg:ease-out mt-4 lg:mt-6`
  const MainLight = `${MainBasic} bg-secondary-soft-black border-secondary-soft-black text-primary-white lg:hover:bg-transparent lg:hover:text-secondary-soft-black`
  const MainDark = `${MainBasic} bg-primary-white border-primary-white text-secondary-soft-black lg:hover:bg-transparent lg:hover:text-primary-white`

  const SecondaryLight = `${MainBasic} border-secondary-soft-black lg:hover:bg-secondary-soft-black lg:hover:text-primary-white`
  const SecondaryDark = `${MainBasic} border-primary-white text-primary-white lg:hover:bg-primary-white lg:hover:text-secondary-soft-black`

  const TextImgBig = `text-center inline-block text-[20px] leading-[28px] font-right-grotesk font-medium underline underline-offset-8 mt-4 lg:mt-6`
  const TextImgBigLight = `${TextImgBig} text-secondary-soft-black decoration-secondary-soft-black`
  const TextImgBigDark = `${TextImgBig} text-primary-white decoration-primary-white`

  const getStyleClassesButton = (ctaStyle: Maybe<string> | undefined | ButtonStyle) => {
    if (noStyle) return
    const baseStylesTextButton = clsx(
      isTextMediumBold && 'font-medium',
      'underline underline-offset-8',
      disabled ? 'text-grey-medium cursor-not-allowed' : 'decoration-primary-black',
      textLinkIsWhite &&
        '!decoration-primary-white hover:decoration-primary-white text-primary-white',
      className && className,
    )

    switch (ctaStyle) {
      case 'Main-light':
        return clsx(MainLight)
      case 'Main-dark':
        return clsx(MainDark)
      case 'Secondary-light':
        return clsx(SecondaryLight)
      case 'Secondary-dark':
        return clsx(SecondaryDark)
      case 'TextImgBig-light':
        return clsx(TextImgBigLight)
      case 'TextImgBig-dark':
        return clsx(TextImgBigDark)
      case 'Main':
        return clsx(
          'button text-primary-white decoration-primary-white',
          disabled
            ? 'bg-grey-medium cursor-not-allowed'
            : 'bg-primary-pink hover:bg-secondary-dark-pink',
        )
      case 'Secondary':
        return clsx(
          'button text-primary-white decoration-primary-white',
          disabled
            ? 'bg-grey-medium cursor-not-allowed'
            : 'bg-primary-black hover:bg-secondary-extra-dark-grey',
        )
      case 'Tertiary':
        return clsx(
          'button border border-solid border-grey-border',
          disabled
            ? 'bg-transparent text-grey-medium cursor-not-allowed'
            : 'text-primary-black decoration-primary-black hover:border-secondary-soft-black hover:bg-secondary-soft-black hover:text-primary-white hover:decoration-primary-white',
        )
      case 'AlternateColor':
        return clsx(
          'button',
          disabled
            ? 'bg-transparent cursor-not-allowed'
            : 'hover:border-secondary-soft-black hover:bg-secondary-soft-black hover:text-primary-white hover:decoration-primary-white',
        )
      case 'BigText':
        return clsx(baseStylesTextButton, 'text-link-txt-big')
      case 'MediumLinkText':
        return clsx(baseStylesTextButton, 'text-[16px] md:text-[22px]')
      case 'MediumText':
        return clsx(baseStylesTextButton, 'text-p')
      case 'SmallText':
        return clsx(baseStylesTextButton, 'text-p-small')
      default:
        return ''
    }
  }

  if (linkInternalUrl) {
    return (
      <Link
        href={linkInternalUrl}
        className={clsx(getStyleClassesButton(ctaStyle || newCtaStyle), className)}
        title={title ?? ''}
      >
        {children ?? ctaText}
      </Link>
    )
  }
  if (linkExternalPage && isExternal(linkExternalPage)) {
    return (
      <Link
        href={linkExternalPage}
        className={clsx(getStyleClassesButton(ctaStyle || newCtaStyle), className)}
        title={title ?? ''}
      >
        {children ?? ctaText}
      </Link>
    )
  }

  if (linkExistingPage?.slug && !isExternal(linkExistingPage.slug) && !linkToEntry?.sys?.id) {
    return (
      <Link
        href={linkExistingPage.slug}
        className={clsx(getStyleClassesButton(ctaStyle || newCtaStyle), className)}
        title={linkExistingPage?.title ?? ''}
      >
        {children ?? ctaText}
      </Link>
    )
  }
  if (phoneLink && !linkExistingPage?.slug && !linkExternalPage) {
    return (
      <Link
        href={`tel:${phoneLink}`}
        className={clsx(getStyleClassesButton(ctaStyle || newCtaStyle), className)}
        title={phoneLink ?? ctaText ?? ''}
      >
        {children ?? ctaText ?? phoneLink}
      </Link>
    )
  }
  if (eMailLink && !linkExistingPage?.slug && !linkExternalPage) {
    return (
      <Link
        href={`mailto:${eMailLink}`}
        className={clsx(getStyleClassesButton(ctaStyle || newCtaStyle), className)}
        title={eMailLink ?? ctaText ?? ''}
      >
        {children ?? ctaText ?? eMailLink}
      </Link>
    )
  }
  if (linkToEntry?.sys?.id && !linkExistingPage?.slug) {
    return (
      <a
        href={`#${linkToEntry.sys.id}`}
        className={clsx(getStyleClassesButton(ctaStyle || newCtaStyle), className)}
      >
        {children ?? ctaText}
      </a>
    )
  }
  if (linkToEntry?.sys?.id && linkExistingPage?.slug) {
    log(`${linkExistingPage?.slug}/#${linkToEntry.sys.id}`)
    return (
      <Link
        href={`${linkExistingPage?.slug}/#${linkToEntry.sys.id}`}
        className={clsx(getStyleClassesButton(ctaStyle || newCtaStyle), className)}
      >
        {children ?? ctaText}
      </Link>
    )
  }

  return (
    <button
      type={type ?? 'button'}
      title={title ?? ctaText ?? ''}
      name={title ?? ctaText ?? 'Custom Button'}
      disabled={disabled}
      className={clsx(getStyleClassesButton(ctaStyle || newCtaStyle), 'inline-block', className)}
      onClick={onClick}
    >
      {children ?? ctaText}
    </button>
  )
}
